import React, { FormEventHandler } from "react"
import {
  Box,
  Button,
  FormControl,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { TextInput } from "../common/textInput/TextInput"
import { z, ZodError } from "zod"
import { styles } from "./Register"

const forgotPasswordZod = z.object({})

interface ForgotPasswordFormType {
  email: {
    value: string
    error?: string
  }
}

interface ForgotPasswordProps {
  linkSent: boolean
  onSubmit: (data: z.infer<typeof forgotPasswordZod>) => void
}

const PasswordSuccess = ({ linkSent, onSubmit }: ForgotPasswordProps) => {
  const [profileFormData, setProfileFormData] =
    React.useState<ForgotPasswordFormType>({
      email: {
        value: "",
        error: "",
      },
    })

  const { t } = useTranslation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(700))

  const onSaveProfile: FormEventHandler = (e) => {
    e.preventDefault()
    forgotPasswordZod
      .parseAsync({
        email: profileFormData.email.value,
      })
      .then((a) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof ForgotPasswordFormType] = {
              ...prev[key as keyof ForgotPasswordFormType],
              error: undefined,
            }
            return acc
          }, {} as ForgotPasswordFormType)
        })
        onSubmit(a)
      })
      .catch((err: ZodError) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof ForgotPasswordFormType] = {
              ...prev[key as keyof ForgotPasswordFormType],
              error: err.issues.find((issue) => issue.path[0] === key)?.message,
            }
            return acc
          }, {} as ForgotPasswordFormType)
        })
      })
  }

  return (
    <Box>
      <Typography variant="body" color={theme.palette.textSecondary?.main}>
        {t("forgotPassword.passSuccessMsg")}
      </Typography>

      <form onSubmit={onSaveProfile}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="ctaPrimary"
            sx={styles.outlinedButton}
            type="submit"
            aria-label={t("forgotPassword.done")}
          >
            {t("forgotPassword.done")}
          </Button>
        </FormControl>
      </form>
    </Box>
  )
}

export default PasswordSuccess
