import React, { FormEventHandler } from "react"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  FormControl,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { TextInput } from "../common/textInput/TextInput"
import { z, ZodError } from "zod"
import { styles } from "./Register"

const forgotPasswordZod = z.object({
  email: z.string().email().min(1, { message: "Required field" }),
})

interface ForgotPasswordFormType {
  email: {
    value: string
    error?: string
  }
}

interface ForgotPasswordProps {
  linkSent: boolean
  onSubmit: (data: z.infer<typeof forgotPasswordZod>) => void
  loading: any
  loginError: any
  setForgotPassError: any
  isSetPasswordPage?: boolean
}

const ForgotPassword = ({
  loading,
  linkSent,
  onSubmit,
  loginError,
  setForgotPassError = false,
  isSetPasswordPage,
}: ForgotPasswordProps) => {
  const [profileFormData, setProfileFormData] =
    React.useState<ForgotPasswordFormType>({
      email: {
        value: "",
        error: "",
      },
    })

  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(700))

  const onSaveProfile: FormEventHandler = (e) => {
    e.preventDefault()
    forgotPasswordZod
      .parseAsync({
        email: profileFormData.email.value,
      })
      .then((a) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof ForgotPasswordFormType] = {
              ...prev[key as keyof ForgotPasswordFormType],
              error: undefined,
            }
            return acc
          }, {} as ForgotPasswordFormType)
        })
        onSubmit(a)
      })
      .catch((err: ZodError) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof ForgotPasswordFormType] = {
              ...prev[key as keyof ForgotPasswordFormType],
              error: err.issues.find((issue) => issue.path[0] === key)?.message,
            }
            return acc
          }, {} as ForgotPasswordFormType)
        })
      })
  }

  return (
    <Box>
      <Typography
        variant="body"
        sx={styles.pageMsgText}
        mb={3}
        fontSize={isSetPasswordPage ? "16px" : ""}
      >
        {t("forgotPassword.subTitle")}
      </Typography>

      <form onSubmit={onSaveProfile} data-cy="forgot-password-submit">
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <TextInput
            autoFocus={true}
            label={t("forgotPassword.email")}
            value={profileFormData.email.value}
            sx={{ ...styles.textInput, marginTop: 2 }}
            disabled={loading}
            labelSx={styles.inputLabel}
            data-cy="forgot-password-modal-email-text-input"
            onChange={(e) => {
              setProfileFormData((prev) => ({
                ...prev,
                email: {
                  ...prev.email,
                  value: e.target.value,
                  error: "",
                },
              }))
              setForgotPassError("")
            }}
            error={!!profileFormData.email.error}
            helperText={profileFormData.email.error}
          />

          {linkSent && (
            <Typography variant="body2" sx={styles.linkSent}>
              {t("forgotPassword.linkSent")}
            </Typography>
          )}
          {loginError ? (
            <Typography color={theme.palette.ctaSecondary?.main} mt={1}>
              {loginError}
            </Typography>
          ) : (
            ""
          )}
          <LoadingButton
            variant="contained"
            color="ctaPrimary"
            sx={
              isSetPasswordPage
                ? {
                    ...styles.setPassGetCodeButton,
                    marginTop: 0,
                    padding: "8px 16px",
                    lineHeight: "24px",
                  }
                : { ...styles.button, marginTop: 0 }
            }
            loading={loading}
            type="submit"
          >
            {t("forgotPassword.getCode")}
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  )
}

export default ForgotPassword
