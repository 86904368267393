import { LoadingButton } from "@mui/lab"
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Link from "next/link"
import React, { FormEventHandler } from "react"
import { useTranslation } from "react-i18next"
import { ZodError, z } from "zod"
import styless from "../../styles/component.module.scss"
import { TextInput } from "../common/textInput/TextInput"
import { styles } from "./Register"

const forgotPasswordZod = z.object({
  verifyCode: z.string().min(1, { message: "Required field" }),
  newPassword: z
    .string()
    .min(1, { message: "Required field" })
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,12}$/,
      {
        message:
          "Must be between 8 and 12 characters and must contain at least 1 character from each of the following: Characters (a-z) (A-Z), Numbers (0-9), Special characters (!, #, $, @, etc.)",
      }
    ),
})

interface ChangePasswordFormType {
  newPassword: {
    value: string
    error?: string
  }
  verifyCode: {
    value: string
    error?: string
  }
}

interface ForgotPasswordProps {
  getCode: () => void
  changePassError: string
  setChangePassError: any
  loading: boolean
  isSetPasswordPage: boolean
  onSubmit: (data: z.infer<typeof forgotPasswordZod>) => void
  email: string
}

const ForgotPassword = ({
  getCode,
  changePassError,
  setChangePassError,
  loading,
  onSubmit,
  email,
  isSetPasswordPage,
}: ForgotPasswordProps) => {
  const [profileFormData, setProfileFormData] =
    React.useState<ChangePasswordFormType>({
      newPassword: {
        value: "",
        error: "",
      },
      verifyCode: {
        value: "",
        error: "",
      },
    })

  const { t } = useTranslation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(700))
  const [showPassword, setShowPassword] = React.useState(false)

  const onSaveProfile: FormEventHandler = (e) => {
    e.preventDefault()
    forgotPasswordZod
      .parseAsync({
        newPassword: profileFormData.newPassword.value,
        verifyCode: profileFormData.verifyCode.value,
      })
      .then((a) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof ChangePasswordFormType] = {
              ...prev[key as keyof ChangePasswordFormType],
              error: undefined,
            }
            return acc
          }, {} as ChangePasswordFormType)
        })
        onSubmit(a)
      })
      .catch((err: ZodError) => {
        setProfileFormData((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return Object.keys(prev).reduce((acc: any, key) => {
            acc[key as keyof ChangePasswordFormType] = {
              ...prev[key as keyof ChangePasswordFormType],
              error: err.issues.find((issue) => issue.path[0] === key)?.message,
            }
            return acc
          }, {} as ChangePasswordFormType)
        })
      })
  }

  return (
    <Box>
      {isSetPasswordPage ? (
        <>
          <Stack
            bgcolor={theme.palette.bgSetPaswordMessage?.main}
            borderRadius="16px"
            p={2}
            mb={2}
          >
            <Typography variant="body1" mb={1} lineHeight="20px">
              {t("setPassword.checkYourEmailIfCodeNotReceived", {
                email: email,
              })}
            </Typography>
          </Stack>
          <Stack
            sx={{
              borderBottom: "2px dashed #DADADA",
              width: "100%",
              margin: "10px 0",
            }}
          ></Stack>
          <Typography
            variant="body"
            my={2}
            sx={{ ...styles.pageMsgText, fontSize: "16px" }}
          >
            {t("forgotPassword.passSubTitle")}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body" my={1} sx={styles.pageMsgText}>
            {t("forgotPassword.passSubTitle")}
          </Typography>
          <Stack
            bgcolor={theme.palette.bgForgotPasswordMessage?.main}
            borderRadius="16px"
            p={2}
            mb={2}
          >
            <Typography variant="body1" mb={1}>
              {t("forgotPassword.checkYourEmailIfCodeNotReceived", {
                email: email,
              })}
            </Typography>

            <Link href="" onClick={getCode} style={{ textDecoration: "unset" }}>
              <Typography
                variant="label"
                fontWeight={600}
                color={theme.palette.ctaPrimary.main}
              >
                {t("forgotPassword.getNewCode")}
              </Typography>
            </Link>
          </Stack>
        </>
      )}

      <form onSubmit={onSaveProfile}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <TextInput
            autoFocus={true}
            label={t("forgotPassword.codeFromEmail")}
            value={profileFormData.verifyCode.value}
            sx={styles.textInput}
            labelSx={styles.inputLabel}
            disabled={loading}
            onChange={(e) => {
              setProfileFormData((prev) => ({
                ...prev,
                verifyCode: {
                  ...prev.verifyCode,
                  value: e.target.value,
                  error: "",
                },
              }))
              setChangePassError("")
            }}
            error={!!profileFormData.verifyCode.error}
            helperText={profileFormData.verifyCode.error}
          />
          <TextInput
            label={t("forgotPassword.newPassword")}
            value={profileFormData.newPassword.value}
            sx={styles.textInput}
            disabled={loading}
            labelSx={styles.inputLabel}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={styles.showBtn}
                    aria-label={
                      showPassword ? t("ariaLabels.hide") : t("ariaLabels.show")
                    }
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setProfileFormData((prev) => ({
                ...prev,
                newPassword: {
                  ...prev.newPassword,
                  value: e.target.value,
                  error: "",
                },
              }))
              setChangePassError("")
            }}
            error={!!profileFormData.newPassword.error}
            helperText={profileFormData.newPassword.error}
          />

          {changePassError ? (
            <Typography
              color={theme.palette.ctaSecondary?.main}
              mt={isSetPasswordPage ? 0 : 3}
            >
              {changePassError}
            </Typography>
          ) : (
            ""
          )}
          <Stack
            sx={{
              fontSize: 13,
              marginTop: "4px",
              fontWeight: 400,
              lineHeight: "20px",
            }}
            ml={2}
            color="textSecondary.main"
          >
            <Typography
              variant="caption"
              fontWeight={400}
              color="textSecondary.main"
            >
              {isSetPasswordPage
                ? t("setPassword.passwordMust")
                : t("register.passwordMust")}
            </Typography>
            <ul className={styless.listItems}>
              <li>{t("register.letters")}</li>
              <li>{t("register.numbers")}</li>
              <li>{t("register.specialCharacters")}</li>
            </ul>
          </Stack>
          <LoadingButton
            variant="contained"
            color="ctaPrimary"
            sx={isSetPasswordPage ? styles.setPassSaveButton : styles.button}
            loading={loading}
            type="submit"
          >
            {isSetPasswordPage
              ? t("setPassword.save")
              : t("forgotPassword.changePassword")}
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  )
}

export default ForgotPassword
